<template>
  <div
    class="chart"
  >
    <canvas 
      id="result-chart"
      ref="chart"
    ></canvas>
  </div>
</template>


<script lang="js">
import Chart from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom'
Chart.register(zoomPlugin)

export default {
  name: 'TheChart',
  props: {
    chartConfig: {
      type: Object
    },
    datapoints: {
      type: Number
    },
    streaming: {
      type: Boolean
    }
  },
  mounted() {
    this.theChart = new Chart(this.$refs.chart, this.chartConfig)
    this.getGradient()
  },
  data() {
    return {
      theChart: null,
      amountOfDataPoints: 0
    }
  },
  methods: {
    updateLimit(limitValue) {
      console.log('limit value', limitValue)

      this.theChart.data.datasets[1].data=[{x:0,y:limitValue},{x:1,y:limitValue}]
      // If chart is streaming, update is automatic in interval timer
      if(!this.streaming) {
        this.update()
      }
    },

    appendData(point, maxPoints) {
      this.amountOfDataPoints = maxPoints
      let length = this.theChart.data.datasets[0].data.length

      // Fill array with zeros if the array is still empty.
      let zeros = this.amountOfDataPoints - length
      while (zeros > 0) {
        this.theChart.data.datasets[0].data.push(0)
        zeros--;
      }

      this.theChart.data.datasets[0].data.push(point)

      // Shift out data from the beginning of the arrays
      let shifts = this.theChart.data.datasets[0].data.length - this.amountOfDataPoints
      while (shifts > 0) {
        this.theChart.data.datasets[0].data.shift();
        shifts--;
      }

      this.update()
    },
    last() {
      return this.theChart.data.datasets[0].data.at(-1)
    },
    dataLength() {
      return this.theChart.data.datasets[0].data.length
    },
    clearData() {
      this.theChart.data.datasets[0].data = []

      // No further action at this point, but might change in future
      // Think of zoom/pan reset, changing labels on scrolling axes, etc.
      this.update()
    },
    update() {
      this.theChart.update('none')
    },
    getGradient() {
      const { ctx, chartArea } = this.theChart
      let gradient = ctx.createLinearGradient(0,chartArea.bottom,0,chartArea.top)
      // Hardcoded for now. Maybe make dependent on limit value
      gradient.addColorStop(0.16,'blue')
      gradient.addColorStop(.8,'red')

      // This is not very satisfying. 
      this.$emit('gradient',gradient)
    }
  }
}
</script>

<style scoped>
.chart {
  width: 70%;
}

@media screen and (max-width: 600px) {
  .chart {
    width: 100%;
  }
}
</style>