<template>
  <div>
    <button
      type="button"
      class="collapsible"
      @click="toggleCollapse"
    >
      {{deviceName}}
      <div class="icons">
        <font-awesome-icon
          icon="circle"
          :class="device.gatt.connected ? 'connected' : 'disconnected'"
        />
        <font-awesome-icon
          :icon="showContent ? 'minus' : 'plus'"
        />
      </div>
    </button>
    <div
      class="collapse-content"
      v-show="showContent"
    >
      <p><strong>Naam:</strong> {{deviceName}}</p>
      <p><strong>Verbonden:</strong> {{device.gatt.connected ? 'Ja' : 'Nee'}}</p>
      <p><strong>Batterij:</strong> {{battLevels[device.name] ? battLevels[device.name].toFixed(2) : "--.-- "}} %</p>
      <button
        class="disconnect-button"
        @click="disconnect"
      >
        Verbinding verbreken
      </button>
    </div>
  </div>
</template>

<script lang="js">
import { actions } from '@/store/devices'
import Vue from 'vue'

import {
  getters as dGetters
} from '@/store/data'


export default Vue.extend({
  name: 'DeviceItem',
  props: {
    device: null
  },
  computed: {
    battLevels() {
      return dGetters.batteryLevels()
    },
    deviceName() {
      
      if (this.device.name.includes('Step')) {
        return this.device.name
      } else {
        return this.device.name + ' ' + this.device.id.substr(0, 3);
      }
    }
  },
  data() {
    return {
      showContent: false,
    }
  },
  methods: {
    toggleCollapse() {
      this.showContent = !this.showContent
    },
    disconnect() {
      actions.disconnect(this.device)
    }
  }
})
</script>

<style lang="scss" scoped>
.collapsible {
  color: white;
  font-size: 1rem;
  background-color: var(--dark-background);
  text-align: left;
  padding: 18px;
  width: 100%;
  border: 1px solid white;
  margin: 0;
}

.collapse-content {
  padding: 0 1rem 1rem;
  background-color: #e0e0e0;
  overflow: hidden;
}

.icons {
  float: right;
  padding: 0 15px;
}

.icons :not(:last-child) {
  padding-right: 10px;
}

.connected {
  color: greenyellow;
}

.disconnected {
  color: red;
}

.disconnect-button {
  float: right;
  border: none;
  color: white;
  padding: 10px 10px;
  background-color: var(--dark-background);
}
</style>