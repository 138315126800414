<template>
  <div>
    <div v-if="!application">
      <h2>Onbekende applicatie</h2>
      <p>Selecteer de applicatie die je wilt gebruiken via het menu</p>
    </div>
    <div v-else>
      <div>
        <h2>{{ application.intro_title }}</h2>
        <button
            class="btn-show-config"
            @click="toggleConfig"
          >
            <font-awesome-icon :icon="showConfig ? 'times' : 'cog'" />
        </button>
      </div>
      <template v-if="application.status == 'IMPLEMENTED'">
        <result
          ref="result"
          v-if="!showConfig"
          @toCalibration="showConfig = true"
        />
        <config
          v-if="showConfig"
          @closeConfig="onCloseConfig"
        />
      </template>
      <template v-else>
        <p>Deze functie is nog in ontwikkeling.</p>
      </template>
    </div>
  </div>
</template>

<script lang="js">
import Vue from 'vue'
import applications from '@/json/applications.json'
import Config from '@/views/Config.vue'
import Result from '@/views/Result.vue'

import sleep from '@/helpers/sleep.ts'

import { getters, actions } from '@/store/config'

export default Vue.extend({
  name: 'FunctionPage',
  components: {
    Config,
    Result
  },
  props: {
    applicationName: String,
  },
  mounted() {
    actions.setApplication(applications.find(a => a.name == this.applicationName))
  },
  computed: {
    configurated() {
      const app = getters.application() == this.application
      return app && getters.configComplete()
    },
    application() {
      return getters.application()
    }
  },
  data() {
    return {
      showConfig: true
    }
  },
  methods: {
    toggleConfig() {
      this.showConfig = !this.showConfig
    },

    async onCloseConfig() {
      console.log('oncloseConfig')
      this.showConfig = false
      this.checkStartGraphReadiness()
    },

    checkStartGraphReadiness() {
      if(this.$refs.result) {
        console.log("No graph object yet")
        setTimeout(function () { this.startGraph() }.bind(this), 500)
      }
      else
      {
        setTimeout(function () { this.checkStartGraphReadiness() }.bind(this), 100)
      }
    },

    startGraph() {
      if(this.$refs.result) {
        this.$refs.result.start()
      } else {
        this.checkStartGraphReadiness()
      }
    }
  },
  watch: {
    applicationName(newValue) {
      actions.setApplication(applications.find(a => a.name == newValue))
      if (this.$refs.result){
        this.$refs.result.clearData()
      }
    },
    configurated(newValue) {
      // await sleep(4000)
      // this.showConfig = !newValue
    }
  }
})
</script>

<style lang="scss" scoped>
.btn-show-config {
  background-color: rgba(255,255,255,0);
  color: var(--navbar-background);
  position: absolute;
  right:.2rem;
  top:12.5vh;
  font-size: 1.2rem;
}
</style>