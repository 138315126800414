<template>
  <div>
    <the-chart
      style="height: 300px"
      :chart-config="chartConfig"
      :streaming="streaming"
      ref="chart"
      @gradient="setGradient"
    />
    <div
      class="alert"
      v-show="latestTestVal > limit"
    >
      <font-awesome-icon icon="exclamation-circle" />
      Alarmlijn overschreden!
    </div>
  </div>
</template>

<script lang="js">
import Vue from 'vue'
import TheChart from '@/components/TheChart.vue'
import {
  getters
} from '@/store/data'
import {
  getters as cGetters
} from '@/store/config'

export default Vue.extend({
  name: 'ChartContainer',
  components: { TheChart },
  props: {
    paused: {
      type: Boolean,
      default: false
    },
    clear: {
      type: Boolean,
      default: false
    },
    chartmode: {
      type: String,
      default: 'relative'
    },
  },
  computed: {
    mode() {
      return this.chartmode
    },
    latest() {
      return getters.latest()
    },
    limit() {
      return cGetters.limit()
    },
    graphYMin() {
      return cGetters.graphMinAngle();
    },
    graphYMax() {
      return cGetters.graphMaxAngle();
    },
    graphXMax() {
      return cGetters.maxDatapoints();
    },
    minAngle() {
      return cGetters.minAngle()
    },
    range() {
      const min = cGetters.minAngle()
      const max = cGetters.maxAngle()
      let range = max - min;
      if (range == 0) {
        range = 100
      }
      return range
    },
    yMin() {
      return cGetters.sensorCount() > 1 ? cGetters.minAngle() - 40 : 0;
    },
    yMax() {
      return cGetters.sensorCount() > 1 ? cGetters.maxAngle() + 40 : 180;
    },
    chartData() {
      return {
        labels: this.getLabels(),
        datasets: [
          {
            xAxisID: 'xRT',
            yAxisId: 'yLeft',
            label: 'test data',
            borderColor: 'rgb(0, 0, 255)',
            backgroundColor: 'rgb(0, 0, 255)',
            fill: false,
            lineTension: 0,
            data: [],
            pointRadius: 0,
          },
          {
            xAxisID: 'xStable',
            yAxisId: 'yRight',
            label: 'Limit value',
            data: [{x:0,y:this.limit}, {x:1, y:this.limit }],
            borderColor: 'rgb(255, 0, 0)',
            backgroundColor: 'rgb(255, 0, 0)',
            pointRadius: 0,
          }
        ]
      }
    },
    options() {
      return {
        spanGaps: true,
        responsive: true,
        maintainAspectRatio: false,
        stacked: true,
        plugins: {
          legend: {
            display: false,
          }
        },
        animation: {
          duration: false
        },
        scales: {
          xRT: {
            id: 'realtimeAxis',
            // min: 0,
            max: this.graphXMax,
            ticks: {
              callback: function(value, index, ticks) {
                return '   '; // Have some bogus empty string to have better spacing between x ticks. All because "stepSize" is not working.
              }
            }
          },
          xStable: {
            id: 'stableAxis',
            min: 0,
            max: 1,
            ticks: {
              display: false
            }
          },
          y1: {
            id: 'yLeft',
            min: this.mode == 'relative' ? -20 : Math.round(this.yMin / 10) * 10,
            max: this.mode == 'relative' ? 100 : Math.round(this.yMax / 10) * 10,
            position: 'left',
            ticks: {
              autoSkip: false,
              stepSize: 10,//this.mode == 'relative' ? 20 : 10,
            }
          },
          y2: {
            id: 'yRight',
            min: this.mode == 'relative' ? -20 : Math.round(this.yMin / 10) * 10,
            max: this.mode == 'relative' ? 100 : Math.round(this.yMax / 10) * 10,
            position: 'right',
            ticks: {
              autoSkip: false,
              stepSize: 10,//this.mode == 'relative' ? 20 : 10,
            }
          }
        }
      }
    },
    chartConfig() {
      return {
        type: 'line',
        data: this.chartData,
        options: this.options
      }
    }
  },
  data() {
    return {
      latestTestVal: 0,
      timer: null,
      streaming: false,
      nullValue: 0,
      // lastTime: new Date().getTime(),
      // diffArray: []
    }
  },
  methods: {
    start() {
      this.streaming = true
    },
    stop() {
      window.clearInterval(this.timer)
      this.streaming = false
    },
    nullIt() {
      if (this.$refs.chart.dataLength() > 0) {
        this.nullValue = this.$refs.chart.last() + this.nullValue;
      }
      else {
        this.nullValue = 0;
      }
    },
    unNullIt() {
      this.nullValue = 0;
    },
    updateChart(val) {
      if (this.$refs.chart && this.streaming) {
        this.$refs.chart.appendData(val, this.graphXMax);
      }
    },
    getLabels() {
      return Array.from({ length: this.graphXMax }, (_,i) => i)
    },
    clearData() {
      this.latestTestVal = 0
      this.$refs.chart.clearData()
      this.$emit('cleared')
    },
    setGradient(gradient) {
      this.chartData.datasets[0].borderColor = gradient
      this.$refs.chart.update()
    },
  },
  watch: {
    graphYMin() {
      let min = !this.graphYMin ? 0 : parseInt(this.graphYMin);
      let max = !this.graphYMax ? 180 : parseInt(this.graphYMax);

      this.options.scales.y1.max = parseInt(max);
      this.options.scales.y1.min = parseInt(min);
      this.options.scales.y2.max = parseInt(max);
      this.options.scales.y2.min = parseInt(min);
    },
    graphYMax() {
      let min = !this.graphYMin ? 0 : parseInt(this.graphYMin);
      let max = !this.graphYMax ? 180 : parseInt(this.graphYMax);

      this.options.scales.y1.max = parseInt(max);
      this.options.scales.y1.min = parseInt(min);
      this.options.scales.y2.max = parseInt(max);
      this.options.scales.y2.min = parseInt(min);
    },
    limit() {
      this.$refs.chart.updateLimit(this.limit)
    },
    latest() {
      let val = this.latest - this.nullValue
      
      if (this.chartmode == 'relative') {
        val = ((this.latest - this.minAngle) / this.range) * 100
        if (val < -20) {
          val = -20
        } else if ( val > 100) {
          val = 100
        }
      }
      this.$emit('violation', (val > this.limit));
      this.updateChart(val)
    },
    
    clear() {
      if (this.clear) {
        this.clearData()
      }
    }
  }
})
</script>

<style scoped>
.alert {
  margin-left: 10vw;
  margin-bottom: .7rem;
  color: red;
}
</style>