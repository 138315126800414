<template>
  <div>
    <sensor-count />
    <chart-mode-select v-if="sensorCount > 0"/>
    <template v-if="showConnect">
      <p v-if="!configComplete">Voltooi de configuratie om resultaten te kunnen zien.</p>
      <connect />
      <template v-if="deviceCount < sensorCount">
        <p>Verbind met minimaal {{ sensorCount }} device(s) om de configuratie uit te voeren.</p>
      </template>
      <template v-else>
        <sensor-positioning
          :positions="application.positions"
        />
      </template>
    </template>
    <template v-if="sensorCount > 1 && deviceCount >= sensorCount">
      <div
        v-if="application.sensors > 1"
        class="alert"
        v-show="equalSelect"
      >
        <font-awesome-icon icon="exclamation-circle" />
        {{$staticText.rugbuiging.config.errEqual}}
      </div>

      <h2>Calibratiegegevens</h2>
      <table>
        <tr>
          <td><strong>Minimale buiging</strong></td>
          <td v-if="minAngle > -90">{{minAngle.toFixed(2)}} graden</td>
          <td v-else >Nog niet gecalibreerd</td>
        </tr>

        <tr>
          <td><strong>Maximale buiging</strong></td>
          <td v-if="maxAngle > -90">{{maxAngle.toFixed(2)}} graden</td>
          <td v-else>Nog niet gecalibreerd</td>
        </tr>
      </table>
      <button
        :disabled="(equalSelect || emptySelect)"
        @click="doCalibration"
      >
        Calibratie starten
      </button>
    </template>
    <p v-show="configComplete">
      Configuratie compleet.
    </p>
    <button
      :disabled="!configComplete"
      @click="$emit('closeConfig')"
    >
      Sluit configuratie
    </button>
    <config-modal
      :show-modal="showModal"
      :step-counter="configStep"
      :total-steps="totalSteps"
      :ok-title="okTitle"
      :ok-icon="okIcon"
      @cancel="onCancel"
      @confirm="onConfirm"
      @close="close"
    />
  </div>
</template>

<script lang="js">
import Vue from 'vue'
import BLEManager from '@/managers/bluetooth.manager'
import ConfigModal from '@/components/config/ConfigModal.vue'
import SensorCount from '@/components/config/SensorCount.vue'
import ChartModeSelect from '@/components/config/ChartModeSelect.vue'
import SensorPositioning from '@/components/config/SensorPositioning.vue'
import Connect from '@/components/connect/Connect.vue'

import { getters, actions } from '@/store/devices'
import { getters as cGetters } from '@/store/config'
import { actions as dActions } from '@/store/data'

export default Vue.extend({
  name: 'Config',
  props: {
    appData: Object,
  },
  components: {
    ConfigModal,
    Connect,
    SensorCount,
    ChartModeSelect,
    SensorPositioning
  },
  computed: {
    showConnect() {
      return cGetters.showConnect()
    },
    sensorCount() {
      return cGetters.sensorCount()
    },
    devices() {
      return getters.devices()
    },
    deviceCount() {
      return getters.deviceCount()
    },
    equalSelect() {
      return false//return (this.upper==this.lower) && (this.lower!='')
    },
    emptySelect() {
      return false//return this.upper == '' || this.lower == ''
    },
    application() {
      return cGetters.application()
    },
    minAngle() {
      return cGetters.minAngle()
    },
    maxAngle() {
      return cGetters.maxAngle()
    },
    configComplete() {
      return cGetters.configComplete()
    },
    okTitle() {
      return this.configStep == this.totalSteps ? 'Afsluiten' : 'Volgende'
    },
    okIcon() {
      return this.configStep == this.totalSteps ? 'chevron-right' : ''
    }
  },
  data() {
    return {
      showModal: false,
      configStep: 1,
      totalSteps: 1,
    }
  },
  methods: {
    doCalibration() {
      this.showModal = true
    },
    onCancel() {
      console.log("onCancel")
    
      BLEManager.getInstance().unsubscribeAllDevices();
      
      dActions.clearData();
      this.configStep = 1;
      dActions.clearData();
      this.close();
    },
    onConfirm() {
      if (this.configStep < this.totalSteps) {
        this.configStep++
      } else {
        this.configStep = 1
        this.close()
      }
    },
    close() {
      this.configStep = 1
      this.showModal = false;
      this.$emit('closeConfig')
    }
  }
})
</script>

<style lang="scss">
.alert {
  color: red;
}
</style>