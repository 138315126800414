<template>
  <div>
    <template v-if="configComplete">
      <div>
        <chart-container
          class="chart"
          ref="chartContainer"
          :chartmode="chartMode" 
          :paused="!subscribed"
          :clear="clearFlag"
          @violation="limitViolated"
          @cleared="chartCleared"
        />
      </div>
      <div id="buttongroup">
        <button
          :disabled="subscribed"
          @click="start"
        >
          <font-awesome-icon icon="play" />
        </button>
        <button
          :disabled="!subscribed"
          @click="stop"
        >
          <font-awesome-icon icon="pause" />
        </button>
        <button
          @click="toggleAudio"
        >
          <font-awesome-icon :icon="enableAudio ? 'volume-up' : 'volume-mute'" />
        </button>
        <button
          :disabled="subscribed"
          @click="clearData"
        >
          <font-awesome-icon icon="trash-alt" />
        </button>
        <button
          :disabled="!subscribed"
          @click="nullIt"
        >
          <font-awesome-icon icon="bullseye" />
        </button>
        <button
          :disabled="!nulled"
          @click="unNullIt"
        >
          <font-awesome-icon icon="arrow-left-rotate" />
        </button>
      </div>

      <div class="limit">
        <div>
          <p v-if="minAngle !== -90 && maxAngle !== -90">
            ingestelde min {{Math.round(minAngle)}}, max {{Math.round(maxAngle)}}
          </p>
        </div>

        <template v-if="sensorCount === 1 && chartMode === 'absolute'">
          <p class="bold">Grafiek</p>
          <p>Pas hieronder de minimale y-as en maximale y-as voor de grafiek hierboven aan</p>
          <div>
            <label for="ymin" class="label-margin">min:</label>
            <input id="ymin" type="number" v-model="graphYMin" />
          </div>
          <div>
            <label for="ymax" class="label-margin">max:</label>
            <input id="ymax" type="number" v-model="graphYMax" />
          </div>

          <p class="alert" v-if="graphYMin > graphYMax">De minimale waarde mag niet groter zijn dan de maximale waarde</p>
        </template>
        
        <p class="bold">Overschrijdingen</p>
        <label class="labelWidth">
          Alarmlijn:
        </label>
        <input
          v-model="limitValue"
          type="number"
        />
        <p>
          <label>
            Notes:
          </label>
          <textarea
            name="notes"
            rows="5"
            cols="40"
            placeholder="Maak je notities hier."
          />
        </p>
        <div
          class="alert"
          v-show="showLimitError"
        >
          <font-awesome-icon icon="exclamation-circle" />
          Alarmlijn mag niet buitend de grafiek liggen!
        </div>
        <p>
          Aantal overschrijdingen: {{ violations }}
        </p>
        <p>
          Overschrijdingstijd: {{ parseFloat(violationTime).toFixed(0) }} seconden.
        </p>
        <p>
          Totaal: {{ parseFloat(totalTime).toFixed(0) }} seconden.
        </p>
        <audio
          ref="audio"
          src="@/assets/sounds/notification.mp3"
          preload
        ></audio>
      </div>

      <!-- <p>Upper: {{latest0}}</p>
      <p>Lower: {{latest1}}</p>
      <p>Comp: {{latest}}</p> -->
    </template>
    <template v-else>
      <p>Voltooi eerst de configuratie om resultaten te kunnen zien.</p>
      <button
      @click="$emit('toCalibration')"
      >
        Naar Configuratie
      </button>
    </template>
  </div>
</template>

<script lang="js">
/// <reference types="web-bluetooth" />

import BLEManager from '@/managers/bluetooth.manager'
import ChartContainer from '@/components/ChartContainer.vue'
import { getters } from '@/store/devices'
import {
  getters as cGetters,
  actions as cActions
} from '@/store/config'
import {
  getters as dGetters,
  actions as dActions
} from '@/store/data'

import Vue from 'vue';

export default Vue.extend({
  name: 'Result',
  components: {
    ChartContainer
  },
  computed: {
    minAngle() {
      return cGetters.minAngle()
    },
    maxAngle() {
      return cGetters.maxAngle()
    },
    devices() {
      return getters.devices()
    },
    application() {
      return cGetters.application()
    },
    chartMode() {
      return cGetters.chartMode()
    },
    configComplete() {
      return cGetters.configComplete()
    },
    sensorCount() {
      return cGetters.sensorCount()
    },
    latest() {
      return dGetters.latest()
    },
    latest0() {
      return dGetters.latest0()
    },
    latest1() {
      return dGetters.latest1()
    },
    dataChars() {
      return getters.dataChars()
    }
  },
  data() {
    return {
      nulled: false,
      subscribed: false,
      limitValue: cGetters.limit(),
      showLimitError: false,
      violations: 0,
      violationTime: 0,
      totalTime: 0,
      enableAudio: true,
      clearFlag: false,
      audioIntervalTimer: null,
      secondTimer: null,
      violationOngoing: false,
      returnFromPause: false,
      graphYMin: cGetters.graphMinAngle(),
      graphYMax: cGetters.graphMaxAngle(),
    }
  },
  methods: {
    start() {
      console.log("Start result graph")
      
      this.subscribe()
      this.$refs.chartContainer.start()

      if (this.returnFromPause && this.violationOngoing) {
        this.returnFromPause = false;
        this.playSound()
      }

      this.violationTime = 0
      this.totalTime = 0
      this.secondTimer = setInterval(() => {
        this.totalTime += 1;
        if (this.violationOngoing) {
          this.violationTime += 1;
        }
      }, 1000); // Audio itself is pretty long.
    },
    stop() {
      this.unsubscribe()
      this.$refs.chartContainer.stop()
      clearInterval(this.secondTimer)

      this.returnFromPause = true;

      if (this.audioIntervalTimer) {
        clearInterval(this.audioIntervalTimer);
        this.audioIntervalTimer = null;
      }
    },
    async subscribe() {
      console.log("Subscribe for result graph")
      this.subscribed = true
    },
    unsubscribe() {
      // BLEManager.getInstance().unsubscribeAllDevices();
      this.subscribed = false
    },
    clearData() {
      dActions.clearData()
      this.clearFlag = true
      this.violations = 0
      this.violationTime = 0
    },
    nullIt() {
      this.$refs.chartContainer.nullIt()
      this.nulled = true;
    },
    unNullIt() {
      this.nulled = false;
      this.$refs.chartContainer.unNullIt()
    },
    chartCleared() {
      this.clearFlag = false
    },

    limitViolated(violated) {
      if (!this.violationOngoing && violated) {
        this.violationOngoing = true;
        this.violations += 1
        this.playSound()

      }
      else if (this.violationOngoing && !violated) {
        this.violationOngoing = false;
        this.stopSound()
      }
    },
    playSound() {
      try {
        if (this.enableAudio) {
        this.$refs.audio.play();

        this.audioIntervalTimer = setInterval(() => {
          this.$refs.audio.play()
        }, 400); // Audio itself is pretty long.
      }
      } catch (e) {
        console.warn('error: ', e);
      }
    },
    stopSound() {
      clearInterval(this.audioIntervalTimer)
      this.audioIntervalTimer = null
    },

    toggleAudio() {
      this.enableAudio = !this.enableAudio
      if (!this.enableAudio) {
        this.stopSound()
      }
    }
  },
  watch: {
    graphYMin() {
      let min = this.graphYMin;
      cActions.setMinGraphAngle(min);
    },
    graphYMax() {
      let max = this.graphYMax;
      cActions.setMaxGraphAngle(max);
    },
    limitValue() {
      let maxVal = 0
      if (this.application.chartmode == 'relative') {
        maxVal = 100
        if (this.limitValue > maxVal) {
          this.showLimitError = true
          this.limitValue = maxVal
        } else if (this.limitValue < 0) {
          this.showLimitError = true
          this.limitValue = 0
        } else {
          cActions.setLimit(this.limitValue)
        }
      } else {
        maxVal = 180
        if (this.limitValue > maxVal) {
          this.showLimitError = true
          this.limitValue = maxVal
        } else if (this.limitValue < 0) {
          this.showLimitError = true
          this.limitValue = 0
        } else {
          cActions.setLimit(this.limitValue)
        }
      }
    }
  }
})
</script>

<style scoped>
.limit {
  margin-bottom: 2em;
}

.alert {
  color: red;
}

.bold {
  font-weight: bold;
}

.label-margin {
  width: 120px;
  display: block;
}

.chart {
  position: relative;
  z-index: -1;
}

#buttongroup {
  text-align: center;
  margin-bottom: .7rem;
  width: 70%;
}

#buttongroup :first-child {
  margin-left:0;
}

@media screen and (max-width: 600px) {
  #buttongroup {
    width: 100%;
  }
}
</style>