<template>
  <modal-base
    :modalId="'config-modal'"
    :showModal="showModal"
    :ok-title="okTitle"
    :ok-icon="okIcon"
    :ok-disabled="okDisabled"
    @cancel="onCloseModal('cancel')"
    @close="onCloseModal('close')"
    @confirm="onCloseModal('confirm')"
  >
    <template v-slot:header>
      <h2>Configuratie</h2>
    </template>

    <h3>Stap {{stepCounter}}/{{totalSteps}}</h3>
      <!-- <p>{{$staticText.rugbuiging.configModal.step1.explanation[stepCounter-1]}}</p> -->
    <template v-if="(stepCounter == 1)">
      <p v-show="measurementBusy">
        Huidige meetwaarde: {{  latest.toFixed(0) }} graden
      </p>
      <p v-show="measurementBusy">
        Ingesteld minimum: {{ minAngle.toFixed(0) }} graden
        <button
          @click="setMinAngle"
        >
          Instellen als minimum
        </button>
      </p>
      <p v-show="measurementBusy">
        Ingesteld maximum: {{ maxAngle.toFixed(0) }} graden
        <button
          @click="setMaxAngle"
        >
          Instellen als maximum
        </button>
      </p>
      <p v-show="!measurementBusy">
          Klik op start meting om de calibratie te starten
      </p>
      <p v-show="!measurementBusy">
        <button
          @click="clickedStart"
        >
          Start meting
        </button>
      </p>
    </template>
      <div
      class="alert"
      v-show="incorrectAngle && (this.maxAngle !== 0 && this.minAngle !== 0)"
    >
      <font-awesome-icon icon="exclamation-circle" />
      De maximale hoek mag niet kleiner zijn als de minimale hoek
    </div>
  </modal-base>
</template>

<script lang="js">
import Vue from 'vue'
import BLEManager from '@/managers/bluetooth.manager'
import ModalBase from '@/components/layout/ModalBase.vue'

import {
  actions as cActions
} from '@/store/config'
import {
  getters as dGetters,
  actions as dActions
} from '@/store/data'
import {
  getters
}  from '@/store/devices'


export default Vue.extend({
  name: 'ConfigModal',
  components: {
    ModalBase
  },
  computed: {
    devices() {
      return getters.devices()
    },
    angles() {
      return dGetters.computedAngles()
    },
    latest() {
      return dGetters.latest()
    },
    incorrectAngle() {
      return this.maxAngle <= this.minAngle;
    },
    incorrectStepCounter() {
      return this.stepCounter == this.totalSteps;
    },
    okDisabled() {
      return (this.incorrectStepCounter &&
             this.incorrectAngle)
    }
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    stepCounter: {
      type: Number,
      default: 1
    },
    totalSteps: {
      type: Number,
      default: 1
    },
    okTitle: {
      type: String,
      default: 'Volgende'
    },
    okIcon: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      maxAngle: 0.0, //degrees
      minAngle: 0.0, //degrees
      sampleLength: 3,
      measurementBusy: false,
    }
  },
  methods: {
    clickedStart() {
      this.maxAngle = 0,
      this.minAngle = 0,
      dActions.clearData()
      this.measurementBusy = true
    },
    setMinAngle() {
      this.minAngle = this.latest
      cActions.setMinAngle(this.latest )
    },
    setMaxAngle() {
      this.maxAngle = this.latest
      cActions.setMaxAngle(this.latest )
    },
    onCloseModal(event) {
      this.$emit(event);
    }
  }
})
</script>