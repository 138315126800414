<template>
  <tr>
    <td><strong>{{ capitalized(position) }}</strong></td>
    <td>
      <select
        :value="position"
        @change="(e) => updatePosition(e, position)"
      >
        <option disabled value="">Selecteer een sensor</option>
        <option v-for="d in devices" :key="d.id">
          {{d._deviceName}}
        </option>
      </select>
    </td>
  </tr>
</template>

<script lang='js'>
import Vue from 'vue'
import { actions } from '@/store/config'
import { getters } from '@/store/devices'

export default Vue.extend({
  name: 'SensorSelect',
  props: {
    position: String,
  },
  computed: {
    devices() {
      return getters.devices()
    },
  },
  methods: {
    capitalized(text) {
      return text[0].toUpperCase() + text.slice(1)
    },
    updatePosition(event, position) {
      actions.setPosition(event.target.value, position)
    }
  }
})
</script>