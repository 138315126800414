<template>
  <div>
    <h2>Sensorposities</h2>
    <table>
    <template v-if="sensorCount > 1">
      <template v-for="p of positions">
        <sensor-select
          :key="p"
          :position="p"
        />
      </template>
    </template>
    <template v-else>
      <sensor-select
        :key="positions[0]"
        :position="positions[0]"
      />
    </template>
    </table>
  </div>
</template>

<script lang='js'>
import Vue from 'vue'
import {getters as cGetters} from '@/store/config'
import SensorSelect from '@/components/config/SensorSelect'

export default Vue.extend({
  name: 'SensorPositioning',
  props: {
    positions: Array,
  },
  components: {
    SensorSelect
  },
  computed: {
    sensorCount() {
      return cGetters.sensorCount()
    },
  }
})
</script>
