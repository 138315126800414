<template>
  <div>
    <h2>Sensoraantal</h2>
    <p>Selecteer het aantal sensoren dat je wilt gebruiken</p>
    <tr>
        <td>
            <button :class="{buttonSelected: singleSensor }"  @click="selectSensorCount(1)">
                {{`Gebruik 1 sensor`}}
            </button>
        </td>
        <td>
            <button :class="{buttonSelected: multipleSensors }" @click="selectSensorCount(2)">
                {{`Gebruik 2 sensoren`}}
            </button>
        </td>
    </tr>
  </div>
</template>

<script lang='js'>

import Vue from 'vue'
import { actions, getters as cGetters } from '@/store/config'

export default Vue.extend({
  name: 'SensorCount', 
  computed: {
    sensorCount() {
      return cGetters.sensorCount()
    },
    singleSensor() {
      return cGetters.sensorCount() === 1;
    },
    multipleSensors() {
      return cGetters.sensorCount() === 2;
    }
  },
  methods: {
    selectSensorCount(count) {
      actions.setSensorCount(count)
      actions.setShowConnect(true)
    },
  
  }
})
</script>