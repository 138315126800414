











































import Vue from 'vue'
export default Vue.extend({
  name: 'ModalBase',
  props: {
    modalId: {
      type: String,
      default: 'base-modal'
    },
    okTitle: {
      type: String,
      default: 'OK'
    },
    okIcon: {
      type: String,
      default: ''
    },
    okDisabled: {
      type: Boolean,
      default: false
    },
    cancelTitle: {
      type: String,
      default: 'Annuleren'
    },
    showModal: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },
    cancel() {
      this.$emit('cancel')
    },
    clickedModal(event: Event) {
      const clickEvent = event.target as HTMLDivElement
      if(clickEvent.id == this.modalId) {
        // this.close()
      }
    },
    close() {
      this.$emit('close')
    }
  }
})
