<template>
  <div>
    <h2>Verbonden sensors</h2>
    <template v-if="devices.length == 0">
      <p>Geen sensoren verbonden.</p>
    </template>
    <template v-else>
      <div class="device-list" >
        <device-item
          v-for="d in devices"
          :key="d._device.id"
          :device="d._device"
        />
      </div>
    </template>
    <button v-show="devices.length < sensorCount"
      @click="scanForDevices"
    >
      Zoek naar sensoren
    </button>
  </div>
</template>

<script lang='js'>
import Vue from 'vue'
import DeviceItem from '@/components/connect/DeviceItem.vue'

import { getters, actions } from '@/store/devices'
import { getters as cGetters } from '@/store/config'

export default Vue.extend({
  name: 'Connect',
  components: {
    DeviceItem
  },
  computed: {
    sensorCount() {
      return cGetters.sensorCount()
    },
    devices() {
      return getters.devices()
    }
  },
  methods: {
    async scanForDevices() {
      actions.scanForDevices()
    }
  }
})
</script>
