<template>
  <div>
    <h2>Grafiek modus</h2>
    <p>Selecteer de modus voor de grafiek</p>
    <tr>
        <td>
            <button :class="{buttonSelected: chartMode === 'relative' }" @click="selectChartMode('relative')" :disabled="sensorCount === 1">
                Gebruik percentages
            </button>
        </td>
        <td>
            <button :class="{buttonSelected: chartMode === 'absolute' }" @click="selectChartMode('absolute')">
                Gebruik graden
            </button>
        </td>
    </tr>
    <p>Selecteer hieronder de tijd die wordt weergegeven in de grafiek</p>
    <tr>
        <td class="labelPadding"><strong>Tijd</strong></td>
        <td>  
          <select id="xval" @change="(e) => updateGraphX(e)">
            <option value=66>5 seconden</option>
            <option value=132>10 seconden</option>
            <option value=198>15 seconden</option>
            <option value=396>30 seconden</option>
          </select>
        </td>
    </tr>
  </div>
</template>

<script lang='js'>

import Vue from 'vue'
import { getters } from '@/store/devices'
import ChartMode from '@/types/chart_modes'
import { actions, getters as cGetters } from '@/store/config'

export default Vue.extend({
  name: 'ChartMode',  
  computed: {
    application() {
      return cGetters.application()
    },
    sensorCount() {
      return cGetters.sensorCount()
    },
    chartMode() {
      return cGetters.chartMode()
    },
  },
  methods: {
      selectChartMode(mode) {
        actions.setChartMode(mode);
        actions.setConfigState();
      },
      updateGraphX(event) {
        actions.setMaxDatapoints(parseInt(event.target.value));
      }
  }
})
</script>

<style lang="scss">
  .labelPadding {
    padding-right: 0.25rem;
  }
</style>